import React, { useEffect, useState, useRef } from 'react'
import { Container, Grid, Box } from '@mui/material';
const Home = () => {

    return (
        
        <Container maxWidth={'xl'}>
            {/* HOME START */}
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img className='image_' src={'./assets/megsapp-logo-white.png'}/>
            </Box>
            {/* HOME END */}
        </Container>
        
    )
}
export default Home
