import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes } from './routing/Routes'
import Header from "./components/Layout/Header/Header";

// import fonts
import VAGRoundedBlack from './resources/fonts/VAGRounded-Black.woff';
import VAGRoundedBlack2 from './resources/fonts/VAGRounded-Black.woff2';
import './App.css';

const OpenSans =  "'Open Sans', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
        light: '#f7f7f7',
        main: '#002846',
        contrastText: '#fff'
      },
      secondary: {
        light: '#f7f7f7',
        main: '#e41f26',
        contrastText: '#fff',
      },
      info: {
        main: '#f7f7f7',
        contrastText: '#002846',
      },
  },
  typography: {
    "fontFamily": [
      'VAG-Rounded-Black',
       OpenSans
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'VAG Rounded';
          src: url(${VAGRoundedBlack2}) format('woff2'),
              url(${VAGRoundedBlack}) format('woff');
          font-style: normal;
          font-display: swap;
        },
      `,
    },
  },
});

theme.typography.h1 = {
  fontSize: '1.5rem',
  [theme.breakpoints.up('xs')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.typography.h3 = {
  fontSize: '1.5rem',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.typography.h5 = {
  fontSize: '1.2rem',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};


function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <ThemeProvider theme={theme}>
            {/* <Header/> */}
            <Routes/>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
